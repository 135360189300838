<template>
    <div id="wrapper">
        <img id="logo" src="@/assets/web-figures/logo.png" />
        <p id="header" :style="styles.header">お問い合わせありがとうございます。</p>
        <p id="body" :style="styles.body">
            内容を順次確認し、折り返させていただきます。<br>
            <br>
            3 営業日以内に返信が確認できなかった場合は、<br>
            お手数ですが info@tutti.ai ( フッターに記載あり ) までご連絡をお願いいたします。
        </p>
    </div>
</template>
<script>
export default {
    computed: {
        styles() {
            return {
                header: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '28px' : '18px',
                },
                body: {
                    fontSize: this.$vuetify.breakpoint.forPC ? '16px' : '14px',
                }
            }
        }
    }
}
</script>
<style scoped>
#wrapper {
    text-align: center;
    padding: 100px 20px 0 20px;
}
#logo {
    width: 300px;
}
#header {
    font-weight: 700;
    padding: 15px 0;
}
#body {
    padding: 20px 0;
}
</style>
